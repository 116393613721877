// NavbarLinks.js

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "../../themes/device"
import theme from "../../themes"

const NavItem = styled(Link)`
  text-decoration: none;
  color: ${props => (props.color ? props.color : "#FFF")};
  display: inline-block;
  white-space: nowrap;
  margin: 16px 32px;
  transition: all 200ms ease-in;
  position: relative;
  font-weight: bold;

  @media ${device.laptop} {
    font-size: 22px;
    font-weight: normal;
  }

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: black;
    height: 4px;
    transition: all 0.4s ease-in;
  }

  :hover {
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

const Links = ({ color }) => {
  return (
    <>
      <NavItem color={color} to="/">
        Startseite
      </NavItem>
      <NavItem color={color} to="/webentwicklung">
        Webentwicklung
      </NavItem>
      <NavItem color={color} to="/referenzen">
        Referenzen
      </NavItem>
      <NavItem color={color} to="/kontakt">
        Kontakt
      </NavItem>
    </>
  )
}

export default Links
