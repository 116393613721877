const colors = {
  black: "#000000",
  white: "#ffffff",
  red: "#ff203c",
  orange: "#ff5d00",
  lightOrange: "#f64d0a",
  yellow: "#ffd500",
  green: "#27b70f",
  blue: "#0097f7",
  teal: "#5bc0de",
  pink: "#ff5b77",
  purple: "#613d7c",
  gray: "#464a4c",
  border: "#E3E9F3",
  "gray-dark": "#292b2c",
  grayLight: "#636c72",
  "gray-lighter": "#eceeef",
  "gray-lightest": "#f7f7f9",
  brightGrey: "#f0f3f8",
  darkGrey: "#e3e9f3",
  lightGrey: "#fafafa",
  lightestGrey: "#fbfbfb",
  mediumGrey: "#F2F3F4",
  grey: "#9ea7b8",
  greyDark: "#292b2c",
  greyAlpha: "rgba(227, 233, 243, 0.5)",
  lightBlue: "#E6F0FB",
  mediumBlue: "#007EFF",
  darkBlue: "#AED4FB",
  content: {
    background: "#fafafb",
    "background-alpha": "rgba(14, 22, 34, 0.02)",
  },
  leftMenu: {
    "link-hover": "#1c2431",
    "link-color": "#919bae",
    "title-color": "#5b626f",
  },
  brand: {
    primary: "#464FD6",
  },
}

export default colors
