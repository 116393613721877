import styled from "styled-components"

const Link = styled.a`
  color: white;
  margin-bottom: 8px;
  display: block;
  text-decoration: none;
`

export default Link
