import styled from "styled-components"
import logo from "../../images/logo.svg"

const Logo = styled.img.attrs({
  src: logo,
  attr: "Logo",
})`
  width: 64px;
`

export default Logo
