import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const H3 = styled.h3`
  font-size: ${theme.main.sizes.typography.mobile.h3};
  line-height: 36px;
  margin: 0 0 8px 0;
  color: ${props => (props.color ? props.color : theme.main.colors.black)};

  @media ${device.laptop} {
    font-size: ${theme.main.sizes.typography.tablet.h3};
    line-height: 38px;
    margin: ${props => (props.marginMd ? props.marginMd : props.marginSm)};
  }

  @media ${device.laptopL} {
    font-size: ${theme.main.sizes.typography.desktop.h3};
    line-height: 48px;
    margin: ${props => (props.marginLg ? props.marginLg : props.marginMd)};
  }
`

export default H3
