import React from "react"
import styled from "styled-components"
import Logo from "./Logo"
import H2 from "../H2"
import H3 from "../H3"
import Container from "../Container"
import Link from "./Link"
import Address from "./Address"
import FooterLink from "./Link"
import Meteor from "./Meteor"
import Slogan from "./Slogan"
import { Grid } from "@material-ui/core"
import List from "../List"
import ListItem from "../ListItem"

const StyledFooter = styled.footer`
  color: white;
  background: #12377c;
  margin-bottom: 0;
  padding: 64px 0;
  box-sizing: border-box;
`

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Link to="/">
              <Logo />
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <H3 color={"white"}>codeflow</H3>
            <Address color={"white"}>
              Neustadtstrasse 8a
              <br />
              6003 Luzern
              <br />
              Schweiz
              <br />
              <br />
              <FooterLink href="mailto:info@codeflow.ch">
                info@codeflow.ch
              </FooterLink>
              <FooterLink style={{ color: "white" }} href="tel:+41791792727">
                +41 79 179 27 27
              </FooterLink>
            </Address>
          </Grid>
          <Grid item xs={12} md={4}>
            <H3 color={"white"}>Webentwicklung</H3>
            <List>
              <li>
                <Link href="/webentwicklung">Überblick</Link>
                <Link href="/webentwicklung#eight-seconds">
                  Was Sie wissen müssen
                </Link>
                <Link href="/webentwicklung#loading">Ladezeiten</Link>
                <Link href="/webentwicklung#responsive">Responsive Design</Link>
                <Link href="/webentwicklung#steps">
                  Ihre Website in 5 Schritten
                </Link>
              </li>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <H3 color={"white"}>Weiteres</H3>
            <List>
              <li>
                <Link href="/impressum">Impressum</Link>
                <Link href="/datenschutzerklaerung">Datenschutz</Link>
              </li>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Slogan>Die kreative Webagentur aus Luzern.</Slogan>
      </Container>
    </StyledFooter>
  )
}

export default Footer
