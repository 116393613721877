import styled from "styled-components"
import theme from "../../themes"

const Box = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 80px;
    left: ${props => (props.open ? "-100%" : "0")};
    background: ${theme.main.colors.brand.primary};
  }
`
export default Box
