import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const Container = styled.div`
  padding: ${props =>
    props.padding ? props.padding : `0 ${theme.main.sizes.paddings.default}`};
  margin: 0 auto;

  transition: 0.3s;

  @media ${device.tablet} {
    padding: 0 40px;
  }

  @media ${device.laptop} {
    padding: 0 40px;
    max-width: 960px;
    margin: 0 auto;
  }

  @media ${device.laptopL} {
    padding: 0 40px;
    max-width: 1440px;
    margin: 0 auto;
  }
`

export default Container
