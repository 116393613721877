import styled from "styled-components"

const Slogan = styled.span`
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
`
export default Slogan
