import styled from "styled-components"

const Toggle = styled.button`
  display: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  @media (max-width: 768px) {
    display: flex;
  }
`

export default Toggle
