import React, { useState } from "react"
import styled from "styled-components"
import theme from "../../themes"
import Logo from "./Logo"
import Toggle from "./Toggle"
import Hamburger from "./Hamburger"
import Box from "./Box"
import Links from "./Links"
import Slogan from "./Slogan"
import Container from "../Container"
import { Link } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { device } from "../../themes/device"

const Inner = styled(Container)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-items: center;
`

const Navbar = styled.div`
  width: 100%;
  max-width: 100%;
  position: fixed;
  display: flex;
  color: ${theme.main.colors.black};
  justify-content: space-between;
  box-sizing: border-box;
  height: ${theme.main.sizes.navbar.mobile};
  background: ${theme.main.colors.white};
  // padding: ${theme.main.sizes.paddings.default};
  border-bottom: 4px solid ${theme.main.colors.brand.primary};
  z-index: 20;

  top: ${props => (props.showHidden ? "0" : "-80px")};
  transition: 0.4s;

  @media ${device.laptop} {
    width: 100%;
  }

`

const Main = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [showHidden, setHidden] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    const position = currPos.y
    if (position === 0) {
      setHidden(false)
    } else if (position < -80) {
      setHidden(true)
    }
  })

  return (
    <Navbar showHidden={showHidden}>
      <Inner>
        <Slogan>
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "black",
            }}
          >
            <Logo />
            codeflow
          </Link>
        </Slogan>
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
        {navbarOpen ? (
          <Box>
            <Links color={"white"} />
          </Box>
        ) : (
          <Box open>
            <Links color={"black"} />
          </Box>
        )}
      </Inner>
    </Navbar>
  )
}

export default Main
