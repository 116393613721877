import styled from "styled-components"
import meteor from "../../images/footer_meteor.svg"
import { device } from "../../themes/device"

const Meteor = styled.img.attrs({
  src: meteor,
})`
  margin-top: 32px;
  width: 100%;

  @media ${device.laptop} {
    max-width: 60%;
  }
`

export default Meteor
