import React, { useState, useEffect } from "react"
import Box from "./Box"
import Link from "./Link"
import Close from "./Close"
import Cookies from "js-cookie"

export default function CookieMessage() {
  const [data, setData] = useState(Cookies.get("codeflow"))

  useEffect(() => {
    if (data) {
      Cookies.set("codeflow", true)
    }
  }, [data])

  if (!data)
    return (
      <Box>
        Diese Website verwendet Cookies für Analysen, personalisierte Inhalte
        und Werbung. Indem Sie diese Website nutzen, erklären Sie sich mit
        dieser Verwendung einverstanden.{" "}
        <Link href="/datenschutzerklaerung"> Mehr erfahren </Link>
        <Close onClick={() => setData(true)}>X</Close>
      </Box>
    )

  return <></>
}
