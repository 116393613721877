import styled from "styled-components"

const Close = styled.button`
  font-size: inherit;
  background: none;
  border: 1px solid white;
  color: white;
  position: absolute;
  right: 4px;
  top: 4px;
`

export default Close
