import styled from "styled-components"
import { device } from "../../themes/device"
import theme from "../../themes"

const Address = styled.address`
  color: ${props => (props.color ? props.color : theme.main.colors.black)};
  font-size: ${theme.main.sizes.typography.mobile.p};
  line-height: 30px;
  font-style: normal;

  @media ${device.tablet} {
    font-size: ${theme.main.sizes.typography.tablet.p};
    line-height: 28px;
    margin: 0 0 40px 0;
  }

  @media ${device.laptopL} {
    font-size: ${theme.main.sizes.typography.desktop.p};
    line-height: 34px;
    margin: 0 0 26px 0;
  }
`

export default Address
