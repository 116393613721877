import styled from "styled-components"
import P from "../P"
import { device } from "../../themes/device"

const Slogan = styled.p`
  color: #ffffff;
  text-align: center;

  @media ${device.laptop} {
    margin-top: 40px;
  }
`

export default Slogan
