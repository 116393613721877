import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const P = styled.p`
  // color: ${props => (props.color ? props.color : theme.main.colors.black)};
  color: inherit;
  font-size: ${theme.main.sizes.typography.mobile.p};
  line-height: 30px;
  margin: ${props => (props.margin ? props.margin : "0 0 16px 0")};


  @media ${device.tablet} {
    font-size: ${theme.main.sizes.typography.tablet.p};
    line-height: 28px;
    margin: ${props => (props.marginSm ? props.marginSm : "0 0 40px 0")};
  }

  @media ${device.laptop} {
    font-size: ${theme.main.sizes.typography.desktop.p};
    line-height: 34px;
    margin: ${props => (props.marginMd ? props.marginMd : "0 0 40px 0")};
  }


  @media ${device.laptopL} {
    font-size: ${theme.main.sizes.typography.desktop.p};
    line-height: 34px;
    margin: ${props => (props.marginLg ? props.marginLg : props.marginMd)};
  }

`

export default P
