/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./normalize.css"
import "../../../static/fonts.css"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { createGlobalStyle } from "styled-components"
import theme from "../../themes"
import Navbar from "../Navbar"
import navitems from "../../data/navigation"
import Footer from "../Footer"
import { useGlobal, setGlobal } from "reactn"
import { device } from "../../themes/device"
import CookieMessage from "../CookieMessage"

setGlobal({
  overflow: false,
})

const GlobalStyle = createGlobalStyle`

* {
  box-sizing: border-box;
  font-family: Quicksand;
}

  body {
    font-family: Quicksand;
    box-sizing: border-box;
  }

  html, body {
    overflow: ${props => (props.overflow ? "hidden" : "visible")};
  }


  a {
    font-size: ${theme.main.sizes.typography.mobile.p};
    @media ${device.tablet} {
    font-size: ${theme.main.sizes.typography.tablet.p};
    line-height: 28px;
    }

  @media ${device.laptopL} {
    font-size: ${theme.main.sizes.typography.desktop.p};
    line-height: 34px;
  }

}
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle overflow={useGlobal("overflow")[0]} theme={theme} />

      <Navbar
        title="hello"
        data={navitems}
        theme={theme}
        siteTitle={data.site.siteMetadata.title}
      >
        yeah
      </Navbar>
      <main>{children}</main>
      <Footer />
      <CookieMessage />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
