import styled from "styled-components"

const Box = styled.div`
  color: white;
  background: black;
  padding: 8px;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  font-size: 14px;
`

export default Box
