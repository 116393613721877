import logo from "../../images/logo.svg"
import styled from "styled-components"

const Logo = styled.img.attrs({
  src: logo,
  alt: "Logo",
})`
  width: 40px;
  margin-right: 16px;
`

export default Logo
