import theme from "../../themes"
import styled from "styled-components"
import { device } from "../../themes/device"

const ListItem = styled.li`
  position: relative;
  border: ${props => (props.border ? props.border : "none")};
  border-bottom: ${props => (props.borderBottom ? props.borderBottom : "none")};
  padding: ${props => (props.padding ? props.padding : "0")};

  &::before {
    display: block;
    content: ">";
    font-weight: bold;
    font-family: Quicksand;
    position: absolute;
    left: 0;
    top: 18px;
    width: 32px;
    height: 32px;

    @media ${device.laptop} {
      font-size: ${theme.main.sizes.typography.desktop.p};
      top: 16px;
    }
  }
`

export default ListItem
